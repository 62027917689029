import React from 'react';
import { ExtraFooter, LoginImageSlider } from '../../src/common/components';

function LoginPage() {
  return (
    <>
      <div className="login">
        <div className="login__hero">
          <LoginImageSlider />
        </div>
      </div>
      <ExtraFooter />
    </>
  );
}

export default LoginPage;
